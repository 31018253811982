import storageAvailable from "./storageAvailable";

const FRONTEGG_LOGIN_DISABLED_KEY = "mz-new-login-flow";

// Initialize this value on boot based on the localstorage value. Bad things could
// happen if we change this mid-session.
const NEW_LOGIN_FLOW_ENABLED = newLoginFlowEnabledOnNextLoad();

/**
 * Tracks the current tenant in sessionStorage. The key name matches the
 * Frontegg implementation, which is important, since we still use their admin panel, so
 * it may also read this value.
 */
const FRONTEGG_CURRENT_TENANT_KEY = "FRONTEGG_SEPARATE_TABS_BY_TENANT";

export function fronteggLoginDisabled() {
  return NEW_LOGIN_FLOW_ENABLED;
}

export function setFronteggLoginFlowEnabled(enabled: boolean) {
  if (storageAvailable("localStorage")) {
    return localStorage.setItem(
      FRONTEGG_LOGIN_DISABLED_KEY,
      enabled.toString(),
    );
  }
}

export function newLoginFlowEnabledOnNextLoad() {
  if (storageAvailable("localStorage")) {
    return localStorage.getItem(FRONTEGG_LOGIN_DISABLED_KEY) === "true";
  }
  return false;
}

/**
 * Removes the stored tenant ID for session storage.
 */
export function clearCurrentTenantId() {
  if (storageAvailable("sessionStorage")) {
    return sessionStorage.removeItem(FRONTEGG_CURRENT_TENANT_KEY);
  }
  return null;
}

/**
 * Current tenant for this session.
 */
export function currentTenantId() {
  if (storageAvailable("sessionStorage")) {
    return sessionStorage.getItem(FRONTEGG_CURRENT_TENANT_KEY);
  }
  return null;
}

/**
 * Set the current tenant for this session.
 */
export function setCurrentTenantId(tenantId: string) {
  if (storageAvailable("sessionStorage")) {
    sessionStorage.setItem(FRONTEGG_CURRENT_TENANT_KEY, tenantId);
  }
}
