import { QueryKey } from "@tanstack/react-query";
import { InferResult } from "kysely";

import { queryBuilder } from "../db";
import { executeSqlV2 } from "../executeSqlV2";

export type SubsourcesParams = {
  sourceId: string;
};

/**
 * Fetches subsources for a single source
 */
export function buildSubsourcesQuery(sourceId: string) {
  return queryBuilder
    .selectFrom("mz_sources as s")
    .innerJoin("mz_object_dependencies as od", "od.object_id", "s.id")
    .innerJoin("mz_schemas as sc", "sc.id", "s.schema_id")
    .innerJoin("mz_databases as d", "d.id", "sc.database_id")
    .innerJoin("mz_source_statuses as st", "st.id", "od.referenced_object_id")
    .select([
      "s.id",
      "s.name",
      "d.name as databaseName",
      "sc.name as schemaName",
      "st.status",
      "s.type",
    ])
    .where("od.referenced_object_id", "=", sourceId)
    .orderBy(["d.name", "sc.name", "name"]);
}

/**
 * Fetches subsources for a given source.
 */
export async function fetchSubsources({
  params,
  queryKey,
  requestOptions,
}: {
  params: SubsourcesParams;
  queryKey: QueryKey;
  requestOptions?: RequestInit;
}) {
  const compiledQuery = buildSubsourcesQuery(params.sourceId).compile();

  return executeSqlV2({
    queries: compiledQuery,
    queryKey: queryKey,
    requestOptions,
  });
}

export type Subsource = InferResult<ReturnType<typeof buildSubsourcesQuery>>[0];
