import { User } from "@sentry/react";
import LDClient from "launchdarkly-js-client-sdk";
import {
  LDClient as LDClientType,
  LDContext,
} from "launchdarkly-react-client-sdk";

import config from "~/config";

// We use module state here because we need to ensure the client is only initialized
// once. Because of suspense and concurrent rendering, multiple renders may happen with
// the ref value uninitialized.
let ldClient: LDClientType | null = null;
let ldClientReadyResolve: () => void | null;
let ldClientReady: Promise<void> | null = new Promise((resolve) => {
  ldClientReadyResolve = resolve;
});

export function buildLdContext(user: User): LDContext {
  return {
    kind: "multi",
    ...(config.environmentdOverride
      ? {
          user: {
            anonymous: true,
          },
        }
      : {}),
    ...(config.impersonation
      ? {
          impersonated_organization: {
            key: config.impersonation.organizationId,
          },
        }
      : {}),
    ...(user
      ? {
          user: {
            key: user.id,
            email: user.email,
          },
        }
      : {}),
    ...(user.tenantId
      ? {
          organization: {
            key: user.tenantId,
          },
        }
      : {}),
  };
}

export function useLaunchDarklyClient(user: User) {
  if (ldClient) return ldClient;

  ldClient = LDClient.initialize(config.launchDarklyKey, buildLdContext(user), {
    bootstrap: "localStorage",
    sendEventsOnlyForVariation: true,
  });
  ldClient.on("ready", () => {
    ldClientReadyResolve();
    ldClientReady = null;
  });
  if (ldClientReady) {
    // Trigger suspense if the client isn't ready yet
    throw ldClientReady;
  }
  return ldClient;
}
