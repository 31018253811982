import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { getFronteggErrorMessage } from "~/api/frontegg/fetch";
import Alert from "~/components/Alert";
import { LabeledInput } from "~/components/formComponentsV2";
import TextLink from "~/components/TextLink";
import { AuthContentContainer, AuthLayout } from "~/layouts/AuthLayout";
import { forgotPasswordPath, signupPath } from "~/platform/routeHelpers";
import { useLogin, usePrelogin, useVerifyMfa } from "~/queries/frontegg";

import { EnrollAuthenticator } from "./EnrollAuthenticator";
import { OauthLoginButtons } from "./OauthLoginButtons";

export const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirectUrl") || "/";
  const [mfaEnrolled, setMfaEnrolled] = React.useState(false);
  const loginMutation = useLogin({
    onSuccess: (response) => {
      if (response.mfaRequired) return;
      navigate(redirectUrl, { replace: true });
    },
  });
  const verifyMfaMutation = useVerifyMfa({
    onSuccess: () => {
      navigate(redirectUrl, { replace: true });
    },
  });

  if (mfaEnrolled) {
    navigate(redirectUrl, { replace: true });
  }
  if (
    loginMutation.data &&
    loginMutation.data.mfaRequired &&
    "mfaEnrolled" in loginMutation.data &&
    !loginMutation.data.mfaEnrolled
  ) {
    // Enrolls a new MFA method for new users with force MFA on the organization
    return (
      <AuthLayout>
        <EnrollAuthenticator
          mfaEnrolled={mfaEnrolled}
          setMfaEnrolled={setMfaEnrolled}
          recoveryCode={loginMutation.data.recoveryCode}
          mfaToken={loginMutation.data.mfaToken}
          qrCode={loginMutation.data.qrCode}
        />
      </AuthLayout>
    );
  }
  if (loginMutation.data?.mfaRequired && loginMutation.data.mfaToken) {
    // Verifies existing MFA
    return (
      <MfaForm
        verifyMfaMutation={verifyMfaMutation}
        mfaToken={loginMutation?.data.mfaToken}
      />
    );
  }
  return <LoginForm loginMutation={loginMutation} />;
};

type MfaFormState = {
  mfaToken: string;
  rememberDevice: boolean;
  value: string;
};

export const MfaForm = (props: {
  verifyMfaMutation: ReturnType<typeof useVerifyMfa>;
  mfaToken: string;
}) => {
  const { formState, handleSubmit, register } = useForm<MfaFormState>({
    defaultValues: {
      mfaToken: props.mfaToken,
      rememberDevice: true,
      value: "",
    },
    mode: "onTouched",
  });
  const handleValidSubmit = (values: MfaFormState) => {
    props.verifyMfaMutation.mutate(values);
  };

  return (
    <AuthLayout>
      <AuthContentContainer
        title="Authenticator app"
        subheading={
          <Text>
            To continue, enter the six-digit code from your authenticator app.
          </Text>
        }
      >
        <form onSubmit={handleSubmit(handleValidSubmit)}>
          <VStack spacing="6" alignItems="start">
            {props.verifyMfaMutation.error && (
              <Alert
                variant="error"
                message={getFronteggErrorMessage(
                  props.verifyMfaMutation.error,
                  "MFA verification failed",
                )}
                width="100%"
              />
            )}
            <FormControl isInvalid={!!formState.errors.value}>
              <Input
                {...register("value", {
                  required: "MFA token is required.",
                })}
                autoCorrect="off"
                size="lg"
                variant={formState.errors.value ? "error" : "default"}
                aria-label="Authenticator token"
              />
            </FormControl>
            <FormControl>
              <Checkbox
                {...register("rememberDevice")}
                variant={formState.errors.value ? "error" : "default"}
                width="100%"
              >
                <Text textStyle="text-small">
                  Don&apos;t ask again on this device for 30 days
                </Text>
              </Checkbox>
            </FormControl>
            <Button
              variant="primary"
              size="lg"
              type="submit"
              isLoading={props.verifyMfaMutation.isPending}
              spinner={<Spinner />}
              width="100%"
            >
              Verify
            </Button>
          </VStack>
        </form>
      </AuthContentContainer>
    </AuthLayout>
  );
};

type LoginFormState = {
  email: string;
  showPassword: boolean;
  password: string;
};

export const LoginForm = (props: {
  loginMutation: ReturnType<typeof useLogin>;
}) => {
  const { formState, handleSubmit, register, watch, setValue } =
    useForm<LoginFormState>({
      defaultValues: {
        email: "",
        showPassword: false,
        password: "",
      },
      mode: "onTouched",
    });
  const {
    error,
    isPending: isLoginPending,
    mutate: login,
  } = props.loginMutation;

  const email = watch("email");
  const showPassword = watch("showPassword");
  const {
    data: sso,
    error: preloginError,
    isPending: isPreloginPending,
    mutate: prelogin,
  } = usePrelogin();
  React.useEffect(() => {
    if (sso) {
      window.location.href = sso.address;
      return;
    } else if (sso === false) {
      setValue("showPassword", true);
    }
  }, [setValue, sso]);
  const handleValidSubmit = (values: LoginFormState) => {
    if (!values.password) {
      prelogin({ email });
      return;
    }
    login(values);
  };
  const submitButtonDisabled = Boolean(
    // While we are making requests, or if we are redirecting to an SSO provider.
    isLoginPending || isPreloginPending || sso,
  );

  return (
    <AuthLayout>
      <AuthContentContainer
        title="Sign in"
        subheading={
          <Text>
            New to Materialize?{" "}
            <TextLink as={Link} to={signupPath}>
              Sign Up
            </TextLink>
          </Text>
        }
      >
        <form onSubmit={handleSubmit(handleValidSubmit)}>
          <VStack spacing="6" alignItems="start">
            {(error || preloginError) && (
              <Alert
                variant="error"
                message={getFronteggErrorMessage(
                  error || preloginError,
                  "Login failed",
                )}
                width="100%"
              />
            )}
            <FormControl isInvalid={!!formState.errors.email}>
              <LabeledInput
                label="Email"
                error={formState.errors.email?.message}
                variant="stretch"
              >
                <Input
                  {...register("email", {
                    required: "Email is required.",
                  })}
                  autoCorrect="off"
                  placeholder="name@example.com"
                  size="lg"
                  variant={formState.errors.email ? "error" : "default"}
                />
              </LabeledInput>
            </FormControl>
            {showPassword && (
              <FormControl isInvalid={!!formState.errors.password}>
                <LabeledInput
                  label="Password"
                  error={formState.errors.password?.message}
                  variant="stretch"
                >
                  <Input
                    {...register("password", {
                      required: "Password is required.",
                    })}
                    autoCorrect="off"
                    placeholder="Enter your password"
                    size="lg"
                    type="password"
                    variant={formState.errors.password ? "error" : "default"}
                  />
                </LabeledInput>
                <FormHelperText textAlign="start">
                  <TextLink
                    as={Link}
                    to={`${forgotPasswordPath}?email=${encodeURIComponent(email)}`}
                  >
                    Forgot password?
                  </TextLink>
                </FormHelperText>
              </FormControl>
            )}

            <Button
              variant="primary"
              size="lg"
              type="submit"
              isLoading={submitButtonDisabled}
              spinner={<Spinner />}
              width="100%"
            >
              {showPassword ? "Sign in" : "Continue"}
            </Button>
            <OauthLoginButtons />
          </VStack>
        </form>
      </AuthContentContainer>
    </AuthLayout>
  );
};
