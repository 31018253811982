import { FronteggProvider, FronteggProviderProps } from "@frontegg/react";
import React from "react";

import { trackSignupInHubspot } from "~/analytics/hubspot";
import LoadingScreen from "~/components/LoadingScreen";
import config from "~/config";
import { AUTH_ROUTES } from "~/fronteggRoutes";
import { fronteggQueryKeys } from "~/queries/frontegg";
import { queryClient } from "~/queryClient";
import { fronteggAuthPageBackground, getFronteggTheme } from "~/theme";
import { fronteggLoginDisabled } from "~/utils/frontegg";

const fronteggProviderProps: FronteggProviderProps = {
  localizations: {
    en: {
      loginBox: {
        forgetPassword: {
          submitButtonText: "Reset password",
        },
      },
    },
  },
  contextOptions: { baseUrl: config.fronteggUrl },
  events: {
    signUpComplete: trackSignupInHubspot,
  },
  backgroundImage: fronteggAuthPageBackground,
  themeOptions: getFronteggTheme(),
  authOptions: {
    enableSessionPerTenant: true,
    routes: {
      loginUrl: AUTH_ROUTES.loginPath,
      logoutUrl: AUTH_ROUTES.logoutPath,
    },
    keepSessionAlive: true,
    enforceRedirectToSameSite: true,
  },
};

/**
 * Renders a FronteggProvider if frontegg login is enabled. This component can be
 * removed once the new login flow ships to everyone.
 */
export const FronteggProviderWrapper = ({
  children,
}: React.PropsWithChildren) => {
  const [loading, setLoading] = React.useState(true);

  if (fronteggLoginDisabled()) {
    return children;
  }
  return (
    <>
      {!fronteggLoginDisabled() && loading && <LoadingScreen />}
      <FronteggProvider
        {...fronteggProviderProps}
        customLoader={(isLoading) => {
          if (!isLoading && !fronteggLoginDisabled()) {
            // If we just logged in, there are likely failed frontegg queries that will
            // cause trigger error states if we don't remove them. Invalidating them isn't
            // enough, since the stale value will still be used.
            queryClient.removeQueries({
              queryKey: fronteggQueryKeys.all(),
            });
          }
          setLoading(isLoading);
        }}
      >
        {!loading && children}
      </FronteggProvider>
    </>
  );
};

export const FronteggAdminPanelProvider = () => {
  if (!fronteggLoginDisabled()) return null;

  return (
    <FronteggProvider
      {...fronteggProviderProps}
      authOptions={{
        ...fronteggProviderProps.authOptions,
        routes: {
          // Prevents Frontegg components from rendering since we reuse some of these paths
          loginUrl: "frontegg/logout",
          logoutUrl: "frontegg/logout",
          activateUrl: "frontegg/activate",
          impersonationUrl: "frontegg/impersonate",
          acceptInvitationUrl: "frontegg/invitation/accept",
          forgetPasswordUrl: "frontegg/forget-password",
          resetPhoneNumberUrl: "frontegg/reset-phone-number",
          resetPasswordUrl: "frontegg/reset-password",
          socialLoginCallbackUrl: "frontegg/social/success",
          signUpUrl: "frontegg/sign-up",
          oidcRedirectUrl: "frontegg/oidc/callback",
          samlCallbackUrl: "frontegg/saml/callback",
          magicLinkCallbackUrl: "frontegg/login/magic-link",
          hostedLoginRedirectUrl: "frontegg/callback",
          openAppUrl: "frontegg/redirect",
        },
      }}
      // Provide a custom loader to prevent their loading screen from showing
      customLoader={() => undefined}
    />
  );
};
