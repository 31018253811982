import { useAtom, useAtomValue } from "jotai";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import {
  Navigate,
  Route,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { AppPasswordRoutes } from "~/access/AppPasswordRoutes";
import { hasInvoiceReadPermission } from "~/api/auth";
import { AppInitializer } from "~/components/AppInitializer";
import { LaunchDarklyProvider } from "~/components/LaunchDarklyProvider";
import { RedirectIfImpersonating } from "~/components/RedirectIfImpersonating";
import { AUTH_ROUTES } from "~/fronteggRoutes";
import { BaseLayout } from "~/layouts/BaseLayout";
import { ShellLayout } from "~/layouts/ShellLayout";
import { AuthRoutes } from "~/platform/auth/AuthRoutes";
import UsagePage from "~/platform/billing/UsagePage";
import ClusterRoutes from "~/platform/clusters/ClusterRoutes";
import BlockedState from "~/platform/environment-not-ready/BlockedState";
import { EnvironmentNotReadyRoutes } from "~/platform/environment-not-ready/EnvironmentNotReadyRoutes";
import { EnvironmentOverviewRoutes } from "~/platform/environment-overview/EnvironmentOverviewRoutes";
import IntegrationsRoutes from "~/platform/integrations/IntegrationsRoutes";
import { ObjectExplorerDetailRoutes } from "~/platform/object-explorer/ObjectExplorerDetailRoutes";
import { ObjectExplorerRoutes } from "~/platform/object-explorer/ObjectExplorerRoutes";
import QueryHistoryRoutes from "~/platform/query-history/QueryHistoryRoutes";
import {
  environmentNotReadyPath,
  SHELL_SLUG as HOME_PAGE_SLUG,
  shellPath as homePagePath,
} from "~/platform/routeHelpers";
import ShellRoutes from "~/platform/shell/ShellRoutes";
import { ShellWebsocketProvider } from "~/platform/shell/ShellWebsocketProvider";
import SinkRoutes from "~/platform/sinks/SinkRoutes";
import SourceRoutes from "~/platform/sources/SourceRoutes";
import { useCurrentUser } from "~/queries/frontegg";
import { SentryRoutes } from "~/sentry";
import { regionIdToSlug, useRegionSlugToId } from "~/store/cloudRegions";
import {
  currentEnvironmentState,
  currentRegionIdAtom,
  defaultRegionSelector,
  isEnvironmentReady,
  useEnvironmentsWithHealth,
  useRegionSlug,
} from "~/store/environments";
import { isCurrentOrganizationBlockedAtom } from "~/store/organization";
import { assert } from "~/util";

const InternalRoutes = React.lazy(
  () => import("~/platform/internal/InternalRoutes"),
);

export const AuthenticatedRoutes = () => {
  const { data: user } = useCurrentUser();
  const flags = useFlags();

  return (
    <LaunchDarklyProvider>
      <AppInitializer />
      <SentryRoutes>
        <Route path="/account/*" element={<AuthRoutes />} />
        <Route
          path="/regions/:regionSlug/*"
          element={
            <RestrictIfBlocked>
              <EnvironmentRoutes />
            </RestrictIfBlocked>
          }
        />
        <Route
          path="/access/*"
          element={
            <RedirectIfImpersonating>
              <BaseLayout>
                <AppPasswordRoutes />
              </BaseLayout>
            </RedirectIfImpersonating>
          }
        />
        <Route
          path={`${environmentNotReadyPath}/*`}
          element={
            <RestrictIfBlocked>
              <EnvironmentNotReadyRoutes />
            </RestrictIfBlocked>
          }
        />
        {hasInvoiceReadPermission(user) && (
          <Route path="/usage" element={<UsagePage />} />
        )}
        {flags["internal-apps"] && (
          <Route path="/internal/*" element={<InternalRoutes />} />
        )}
        <Route path="*" element={<RedirectToHome />} />
      </SentryRoutes>
    </LaunchDarklyProvider>
  );
};

type RegionParams = "regionSlug";

const RestrictIfBlocked = ({ children }: React.PropsWithChildren) => {
  const isCurrentOrganizationBlocked = useAtomValue(
    isCurrentOrganizationBlockedAtom,
  );
  if (isCurrentOrganizationBlocked) {
    return (
      <BaseLayout>
        <BlockedState />
      </BaseLayout>
    );
  }
  return children;
};

const EnvironmentRoutes = () => {
  const flags = useFlags();
  const params = useParams<RegionParams>();
  const { data: user } = useCurrentUser();
  const navigate = useNavigate();
  const environments = useEnvironmentsWithHealth();
  const [currentRegionId, setCurrentRegionId] = useAtom(currentRegionIdAtom);
  const [defaultRegion] = useAtom(defaultRegionSelector);
  assert(params.regionSlug);
  const regionId = useRegionSlugToId(params.regionSlug);

  React.useEffect(() => {
    if (regionId && !isEnvironmentReady(environments.get(regionId))) {
      assert(params.regionSlug);
      navigate(environmentNotReadyPath, { replace: true });
    }
  }, [environments, navigate, params.regionSlug, regionId]);

  React.useEffect(() => {
    if (!regionId) {
      navigate(`/regions/${regionIdToSlug(defaultRegion)}`);
      return;
    }

    if (currentRegionId !== regionId) {
      // Synchronize the url with jotai, this happens on navigation to a link to another cluster or back navigation
      setCurrentRegionId(regionId);
    }
  }, [
    defaultRegion,
    currentRegionId,
    navigate,
    params.regionSlug,
    regionId,
    setCurrentRegionId,
  ]);

  if (!regionId) {
    return null;
  }

  return (
    <ShellWebsocketProvider regionId={regionId} organizationId={user.tenantId}>
      <SentryRoutes>
        <Route
          path={`/${HOME_PAGE_SLUG}/*`}
          element={
            <BaseLayout>
              <ShellLayout>
                <ShellRoutes />
              </ShellLayout>
            </BaseLayout>
          }
        />
        <Route
          path="objects/*"
          element={
            <BaseLayout sectionNav={<ObjectExplorerRoutes />}>
              <ObjectExplorerDetailRoutes />
            </BaseLayout>
          }
        />
        <Route
          path="/clusters/*"
          element={
            <BaseLayout>
              <ClusterRoutes />
            </BaseLayout>
          }
        />
        <Route
          path="/sources/*"
          element={
            <BaseLayout>
              <SourceRoutes />
            </BaseLayout>
          }
        />
        <Route
          path="/sinks/*"
          element={
            <BaseLayout>
              <SinkRoutes />
            </BaseLayout>
          }
        />
        {flags["environment-overview-2855"] && (
          <Route
            path="/environment-overview/*"
            element={
              <BaseLayout>
                <EnvironmentOverviewRoutes />
              </BaseLayout>
            }
          />
        )}
        <Route
          path="/query-history/*"
          element={
            <BaseLayout>
              <QueryHistoryRoutes />
            </BaseLayout>
          }
        />
        <Route
          path="/integrations/*"
          element={
            <BaseLayout>
              <IntegrationsRoutes />
            </BaseLayout>
          }
        />
        <Route
          path="/*"
          element={<Navigate to={homePagePath(params.regionSlug)} replace />}
        />
      </SentryRoutes>
    </ShellWebsocketProvider>
  );
};

const RedirectToHome = () => {
  const location = useLocation();
  useEnvironmentsWithHealth();
  const regionSlug = useRegionSlug();
  const navigate = useNavigate();
  const [currentEnvironment] = useAtom(currentEnvironmentState);

  // For Frontegg routes, we return null and let Frontegg handle it.
  const isFronteggRoute = Object.values(AUTH_ROUTES).includes(
    location.pathname,
  );

  React.useEffect(() => {
    if (!isFronteggRoute) {
      /*
       * Note: It's important to use `useNavigate` rather than the `<Navigate>` component since sometimes this flow can happen:
       * 1. Frontegg redirects to the root path
       * 2. We redirect
       * 3. Frontegg redirects to root path again before we can unmount RedirectToHome
       * 4. Navigate gets rendered instead of re-mounted, which doesn't trigger a redirect
       */
      if (!currentEnvironment || !isEnvironmentReady(currentEnvironment)) {
        navigate(environmentNotReadyPath, { replace: true });
      } else {
        navigate(homePagePath(regionSlug), { replace: true });
      }
    }
  });

  return null;
};
