import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";

import {
  newLoginFlowEnabledOnNextLoad,
  setFronteggLoginFlowEnabled,
} from "~/utils/frontegg";

/**
 * Watches the value of the new login flow feature flag and updates localstorage when it
 * changes. This allows us to avoid unmounting the FronteggProvider mid-session, which
 * could could be confusing or break the app.
 */
export const useSyncNewLoginFlowFlag = () => {
  const flags = useFlags();
  const newLoginFlowEnabled = flags["new-login-flow-1398"];

  React.useEffect(() => {
    if (newLoginFlowEnabled && !newLoginFlowEnabledOnNextLoad()) {
      setFronteggLoginFlowEnabled(true);
    }
    if (!newLoginFlowEnabled && newLoginFlowEnabledOnNextLoad()) {
      setFronteggLoginFlowEnabled(false);
    }
  }, [newLoginFlowEnabled]);

  return null;
};
