import { LDProvider } from "launchdarkly-react-client-sdk";
import React from "react";

import config from "~/config";
import {
  buildLdContext,
  useLaunchDarklyClient,
} from "~/hooks/useLaunchDarklyClient";
import { useCurrentUser } from "~/queries/frontegg";

/**
 * A wrapper over LaunchDarkly's LDProvider
 *
 * We use deferInitialization to block rendering the app until the flag values are
 * available. This is important since incorrect flag values can redirect users to the
 * home page on routes that are guarded by feature flags.
 */
export const LaunchDarklyProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { data: user } = useCurrentUser();
  const ldContext = React.useMemo(() => buildLdContext(user), [user]);
  const ldClient = useLaunchDarklyClient(user);

  return (
    <LDProvider
      ldClient={ldClient}
      clientSideID={config.launchDarklyKey}
      reactOptions={{
        useCamelCaseFlagKeys: false,
      }}
      context={ldContext}
      options={{
        bootstrap: "localStorage",
      }}
    >
      {children}
    </LDProvider>
  );
};
