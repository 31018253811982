import {
  DefaultError,
  useMutation,
  UseMutationOptions,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import React from "react";

import { useCanAccessTenantApiTokens } from "~/api/auth";
import {
  buildGlobalQueryKey,
  buildQueryKeyPart,
} from "~/api/buildQueryKeySchema";
import {
  acceptInvitation,
  activateAccount,
  activateStrategy,
  createTenantApiToken,
  createUserApiToken,
  deleteTenantApiToken,
  deleteUserApiToken,
  fetchMfaPolicyAllowRemember,
  fetchRefreshAccessToken,
  fetchRoles,
  fetchTenantApiTokens,
  fetchTenants,
  fetchUser,
  fetchUserApiTokens,
  forgotPassword,
  login,
  logout,
  mfaEnroll,
  oauthConfiguration,
  oidcPostlogin,
  prelogin,
  resetPassword,
  ResetPasswordParams,
  setActiveTenant,
  signup,
  socialPostlogin,
  SocialPostloginParams,
  verifyMfa,
} from "~/api/frontegg";
import {
  ActivateAccountResponse,
  ActivateStrategyParams,
  ApiToken,
  LoginSuccess,
  MfaEnrollParams,
  MfaEnrollResponse,
  MfaRequiredParams,
  MfaRequiredResponse,
  NewApiToken,
  PreloginResult,
  SignupParams,
  SignupResponse,
  SocialLoginSuccess,
  Tenant,
  TenantApiToken,
  TenantsResponse,
  User,
  UserApiToken,
  VerifyMfaParams,
  VerifyMfaResponse,
} from "~/api/frontegg/types";
import {
  clearFronteggToken,
  getFronteggToken,
  refreshToken,
  setFronteggToken,
} from "~/api/fronteggToken";
import config from "~/config";
import { useCachedQueryData } from "~/hooks/useCachedQueryData";
import { ResetStoreContext } from "~/layouts/JotaiProviderWrapper";
import {
  clearCurrentTenantId,
  currentTenantId,
  setCurrentTenantId,
} from "~/utils/frontegg";

export const fronteggQueryKeys = {
  all: () => buildGlobalQueryKey("frontegg"),
  acceptInvitation: () =>
    [
      ...fronteggQueryKeys.all(),
      buildQueryKeyPart("acceptInvitation"),
    ] as const,
  activateAccount: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("activateAccount")] as const,
  activateStrategy: (params: ActivateStrategyParams) =>
    [
      ...fronteggQueryKeys.all(),
      buildQueryKeyPart("activateStrategy"),
      params,
    ] as const,
  user: () => [...fronteggQueryKeys.all(), buildQueryKeyPart("user")] as const,
  roles: (params: { enabled: boolean }) =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("roles"), params] as const,
  apiTokens: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("api-tokens")] as const,
  listApiTokens: (params: { canAccessTenantApiTokens: boolean }) =>
    [...fronteggQueryKeys.apiTokens(), params] as const,
  createApiToken: () =>
    [...fronteggQueryKeys.apiTokens(), buildQueryKeyPart("create")] as const,
  deleteApiToken: () =>
    [...fronteggQueryKeys.apiTokens(), buildQueryKeyPart("delete")] as const,
  forgotPassword: () =>
    [
      ...fronteggQueryKeys.apiTokens(),
      buildQueryKeyPart("forgotPassword"),
    ] as const,
  isLoggedIn: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("isLoggedIn")] as const,
  login: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("login")] as const,
  logout: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("logout")] as const,
  mfaEnroll: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("mfaEnroll")] as const,
  mfaRequired: (params: MfaRequiredParams) =>
    [
      ...fronteggQueryKeys.all(),
      buildQueryKeyPart("activateStrategy"),
      params,
    ] as const,
  verifyMfa: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("verifyMfa")] as const,
  prelogin: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("prelogin")] as const,
  resetPassword: () =>
    [
      ...fronteggQueryKeys.apiTokens(),
      buildQueryKeyPart("forgotPassword"),
    ] as const,
  oidcPostlogin: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("oidcPostlogin")] as const,
  setCurrentTenant: () =>
    [
      ...fronteggQueryKeys.all(),
      buildQueryKeyPart("setCurrentTenant"),
    ] as const,
  socialPostlogin: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("socialPostlogin")] as const,
  signup: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("signup")] as const,
  oauthConfiguration: () =>
    [
      ...fronteggQueryKeys.all(),
      buildQueryKeyPart("oauthConfiguration"),
    ] as const,
  tenants: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("tenants")] as const,
};

function buildFakeUser(overrides?: Partial<User>): User {
  return {
    id: "1",
    name: "Local User",
    email: "local@materialize.com",
    tenantId: "123",
    profilePictureUrl: "",
    sub: "",
    verified: true,
    phoneNumber: null,
    provider: "",
    mfaEnrolled: false,
    metadata: "",
    vendorMetadata: null,
    tenantIds: [],
    roles: [],
    permissions: [],
    createdAt: "",
    lastLogin: "",
    isLocked: false,
    tenants: [],
    managedBy: "frontegg",
    groups: [],
    subAccountAccessAllowed: false,
    activatedForTenant: true,
    ...overrides,
  };
}

export async function queryUser(requestOptions?: RequestInit) {
  if (config.impersonation) {
    return buildFakeUser({
      name: "Impersonation User",
      email: "impersonation@materialize.com",
      tenantId: config.impersonation.organizationId,
      tenantIds: [config.impersonation.organizationId],
    });
  }
  if (config.environmentdOverride) {
    return buildFakeUser();
  }

  const user = await fetchUser(requestOptions);
  const tenantId = currentTenantId();
  if (tenantId) {
    // The user response always includes the active tenant according to the server, which
    // is the tenant the user last switched to. Since we support separate tenant per
    // session, we override that value here.
    user.tenantId = tenantId;
  }
  return user;
}

function buildFakeTenant(overrides?: Partial<Tenant>) {
  return {
    _id: "1",
    vendorId: "1",
    tenantId: "123",
    name: "1",
    deletedAt: null,
    metadata: "",
    isReseller: false,
    creatorEmail: "user@materialize.com",
    creatorName: "user",
    id: "1",
    createdAt: new Date(),
    updatedAt: new Date(),
    __v: 1,
    ...overrides,
  };
}

export async function queryTenants(
  requestOptions?: RequestInit,
): Promise<TenantsResponse> {
  if (config.environmentdOverride) {
    return { tenants: [buildFakeTenant()], activeTenant: buildFakeTenant() };
  }
  return fetchTenants(requestOptions);
}

export function useAcceptInvitation(
  options: UseMutationOptions<
    Response,
    DefaultError,
    { userId: string; token: string }
  > = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.acceptInvitation(),
    mutationFn: acceptInvitation,
    ...options,
  });
}

export function useActivateAccount(
  options: UseMutationOptions<
    ActivateAccountResponse,
    DefaultError,
    { userId: string; token: string; password?: string }
  > = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.activateAccount(),
    mutationFn: async (params) => {
      const response = await activateAccount(params);
      if (response.accessToken) {
        // If mfa is required, they won't get an access token here
        setFronteggToken(response.accessToken);
      }
      return response;
    },
    ...options,
  });
}

export function useActivateStrategy(params: ActivateStrategyParams) {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.activateStrategy(params),
    queryFn: ({ queryKey: [, , queryKeyParams], signal }) =>
      activateStrategy(queryKeyParams, { signal }),
  });
}

export function useCurrentUser() {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.user(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    queryFn: ({ signal }) => queryUser({ signal }),
  });
}

export function useRoles(params: { enabled: boolean } = { enabled: true }) {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.roles(params),
    queryFn: ({ signal }) => (params.enabled ? fetchRoles({ signal }) : []),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}

export function useListApiTokens() {
  const canAccessTenantApiTokens = useCanAccessTenantApiTokens();
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.listApiTokens({ canAccessTenantApiTokens }),
    queryFn: async ({ signal }) => {
      const fetches: Promise<UserApiToken[] | TenantApiToken[]>[] = [
        fetchUserApiTokens({ signal }),
      ];
      if (canAccessTenantApiTokens) {
        fetches.push(fetchTenantApiTokens({ signal }));
      }
      const apiTokens = await Promise.all(fetches);
      return apiTokens
        .flat()
        .toSorted((x, y) => Date.parse(y.createdAt) - Date.parse(x.createdAt));
    },
  });
}

type CreateApiTokenVariables =
  | {
      type: "personal";
      description: string;
    }
  | {
      type: "service";
      description: string;
      user: string;
      roleIds: string[];
    };

function formatAppPassword({ clientId, secret }: NewApiToken) {
  const formattedClientId = clientId.replaceAll("-", "");
  const formattedSecret = secret.replaceAll("-", "");
  const password = `mzp_${formattedClientId}${formattedSecret}`;
  const obfuscatedPassword = `${new Array(password.length).fill("*").join("")}`;
  return { password, obfuscatedPassword };
}

export function useCreateApiToken(
  options?: UseMutationOptions<
    NewApiToken,
    DefaultError,
    CreateApiTokenVariables
  >,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: fronteggQueryKeys.createApiToken(),
    mutationFn: async (params: CreateApiTokenVariables) => {
      if (params.type === "personal") {
        return await createUserApiToken(params);
      } else {
        return await createTenantApiToken(params);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: fronteggQueryKeys.apiTokens(),
      });
    },
    ...options,
  });

  const newPassword = React.useMemo(() => {
    if (!mutation.data) return null;

    const { password, obfuscatedPassword } = formatAppPassword(mutation.data);
    return { ...mutation.data, password, obfuscatedPassword };
  }, [mutation.data]);

  return {
    ...mutation,
    data: newPassword,
  };
}

export function useDeleteApiToken(
  options: UseMutationOptions<Response, DefaultError, { token: ApiToken }> = {},
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: fronteggQueryKeys.deleteApiToken(),
    mutationFn: async (params: { token: ApiToken }) => {
      if (params.token.type === "personal") {
        return await deleteUserApiToken({ id: params.token.clientId });
      } else {
        return await deleteTenantApiToken({ id: params.token.clientId });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: fronteggQueryKeys.apiTokens(),
      });
    },
    ...options,
  });
}

export function useForgotPassword(
  options: UseMutationOptions<Response, DefaultError, { email: string }> = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.forgotPassword(),
    mutationFn: forgotPassword,
    ...options,
  });
}

export function useResetPassword(
  options: UseMutationOptions<Response, DefaultError, ResetPasswordParams> = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.resetPassword(),
    mutationFn: resetPassword,
    ...options,
  });
}

export function useLogout(options: UseMutationOptions = {}) {
  const resetStore = React.useContext(ResetStoreContext);
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: fronteggQueryKeys.logout(),
    mutationFn: async () => {
      await logout();
      clearFronteggToken();
      clearCurrentTenantId();
      queryClient.clear();
      resetStore();
    },
    ...options,
  });
}

export function useOidcLogin(
  options: UseMutationOptions<
    Response,
    DefaultError,
    { code: string; state: string }
  > = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.oidcPostlogin(),
    mutationFn: oidcPostlogin,
    ...options,
  });
}

export function useLogin(
  options: UseMutationOptions<
    LoginSuccess | MfaRequiredResponse,
    DefaultError,
    { email: string; password: string }
  > = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.login(),
    mutationFn: async (params) => {
      const result = await login(params);
      if (result.accessToken) {
        // If mfa is required, and it hasn't been enrolled yet, we won't get an access
        // token here.
        setFronteggToken(result.accessToken);
      }
      return result;
    },
    ...options,
  });
}

/**
 * This hook should only be called in one place, and the caller needs to handle
 * rendering the MFA form when required.
 */
export function useRefreshToken() {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.isLoggedIn(),
    staleTime: 9 * 60 * 1000, // Access token is good for 10 minutes, refresh after 9
    gcTime: Infinity,
    queryFn: async () => {
      if (config.isImpersonating) return { isLoggedIn: true };
      if (getFronteggToken()) return { isLoggedIn: true };

      try {
        const response = await fetchRefreshAccessToken();
        if ("mfaRequired" in response && response.mfaRequired) {
          return {
            isLoggedIn: false,
            response,
          } as const;
        }
        setFronteggToken(response.accessToken);
        return { isLoggedIn: true };
      } catch {
        return { isLoggedIn: false };
      }
    },
  });
}

export type UseIsLoggedInData = ReturnType<typeof useRefreshToken>["data"];

export function useIsLoggedIn() {
  const cachedResult = useCachedQueryData<UseIsLoggedInData>(
    fronteggQueryKeys.isLoggedIn(),
  );
  if (!cachedResult) {
    return { isLoggedIn: false, isInitialized: false };
  }
  return { ...cachedResult, isInitialized: true };
}

export function useMfaPolicyAllowRemember(params: MfaRequiredParams) {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.mfaRequired(params),
    queryFn: ({ queryKey: [, , paramsFromKey], signal }) =>
      fetchMfaPolicyAllowRemember(paramsFromKey, { signal }),
  });
}

export function useMfaEnroll(
  options: UseMutationOptions<
    MfaEnrollResponse,
    DefaultError,
    MfaEnrollParams
  > = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.mfaEnroll(),
    mutationFn: async (params) => {
      const response = await mfaEnroll(params);
      setFronteggToken(response.accessToken);
      return response;
    },
    ...options,
  });
}

export function useVerifyMfa(
  options: UseMutationOptions<
    VerifyMfaResponse,
    DefaultError,
    VerifyMfaParams
  > = {},
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: fronteggQueryKeys.verifyMfa(),
    mutationFn: async (params) => {
      const response = await verifyMfa(params);
      queryClient.setQueryData<UseIsLoggedInData>(
        fronteggQueryKeys.isLoggedIn(),
        {
          isLoggedIn: true,
        },
      );
      setFronteggToken(response.accessToken);
      return response;
    },
    ...options,
  });
}

export function usePrelogin(
  options: UseMutationOptions<
    PreloginResult,
    DefaultError,
    { email: string }
  > = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.prelogin(),
    mutationFn: (params) => {
      return prelogin(params);
    },
    ...options,
  });
}

export function useSignup(
  options: UseMutationOptions<SignupResponse, DefaultError, SignupParams> = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.signup(),
    mutationFn: signup,
    ...options,
  });
}

export function useOauthConfiguration() {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.oauthConfiguration(),
    queryFn: ({ signal }) => oauthConfiguration({ signal }),
  });
}

export function useOauthLogin(
  options: UseMutationOptions<
    SocialLoginSuccess,
    DefaultError,
    SocialPostloginParams
  > = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.socialPostlogin(),
    mutationFn: async (params) => {
      const result = await socialPostlogin(params);
      setFronteggToken(result.accessToken);
      return result;
    },
    ...options,
  });
}

export function useSetActiveTenant(
  options: UseMutationOptions<User, DefaultError, { tenantId: string }> = {},
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: fronteggQueryKeys.setCurrentTenant(),
    mutationFn: async (params: { tenantId: string }) => {
      const user = await setActiveTenant(params);
      setCurrentTenantId(params.tenantId);
      // Since the token includes the current tenant ID, we have to refresh it before we
      // refetch any other data.
      await refreshToken();
      return user;
    },
    onSuccess: (user) => {
      queryClient.setQueryData<User>(fronteggQueryKeys.user(), user);
    },
    ...options,
  });
}

export function useTenants() {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.tenants(),
    queryFn: (requestOptions) => queryTenants(requestOptions),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}
