import React from "react";
import { Route } from "react-router-dom";

import SinksList from "~/platform/sinks/SinksList";
import { SentryRoutes } from "~/sentry";

export type ClusterDetailParams = {
  clusterName: string;
};

const SinkRoutes = () => {
  return (
    <SentryRoutes>
      <Route path="/" element={<SinksList />} />
    </SentryRoutes>
  );
};

export default SinkRoutes;
