import { HStack } from "@chakra-ui/react";
import React from "react";

import PreviewNotice from "~/components/PreviewNotice";
import {
  MainContentContainer,
  PageHeader,
  PageHeading,
} from "~/layouts/BaseLayout";

import MemDiskUtilization from "./MemDiskUtilization";

export const EnvironmentOverview = () => {
  return (
    <MainContentContainer>
      <PageHeader>
        <HStack>
          <PageHeading>Environment Overview</PageHeading>
          <PreviewNotice />
        </HStack>
      </PageHeader>
      <MemDiskUtilization />
    </MainContentContainer>
  );
};

export default EnvironmentOverview;
