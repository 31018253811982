import { QueryKey, useQueryClient } from "@tanstack/react-query";
import React from "react";

export function useCachedQueryData<TData = unknown>(queryKey: QueryKey) {
  const queryClient = useQueryClient();
  const [data, setData] = React.useState<TData | undefined>(() =>
    queryClient.getQueryData<TData>(queryKey),
  );

  React.useEffect(() => {
    const unsubscribe = queryClient.getQueryCache().subscribe((event) => {
      if (event.query.queryKey === queryKey) {
        setData(event.query.state.data);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [queryClient, queryKey]);

  return data;
}
