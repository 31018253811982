import {
  Button,
  FormControl,
  Input,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { getFronteggErrorMessage } from "~/api/frontegg/fetch";
import Alert from "~/components/Alert";
import { LabeledInput } from "~/components/formComponentsV2";
import TextLink from "~/components/TextLink";
import config from "~/config";
import { AuthContentContainer, AuthLayout } from "~/layouts/AuthLayout";
import { loginPath } from "~/platform/routeHelpers";
import { useSignup } from "~/queries/frontegg";

import { OauthLoginButtons } from "./OauthLoginButtons";

type SignupFormState = {
  email: string;
  name: string;
  companyName: string;
};

export const Signup = () => {
  const { formState, handleSubmit, register, watch } = useForm<SignupFormState>(
    {
      defaultValues: {
        email: "",
        name: "",
        companyName: "",
      },
      mode: "onTouched",
    },
  );
  const {
    error: signupError,
    isPending: isSignupPending,
    isSuccess: signupSuccessful,
    mutate: signup,
  } = useSignup();
  const email = watch("email");

  const handleValidSubmit = (values: SignupFormState) => {
    signup(values);
  };

  React.useEffect(() => {
    if (config.externalRegistration) {
      // This generally shouldn't happen, but if a user somehow visits this url directly,
      // redirect them to the marketing signup form.
      window.location.href = "https://materialize.com/register";
    }
  }, []);

  return (
    <AuthLayout>
      {signupSuccessful ? (
        <SignupSuccess email={email} />
      ) : (
        <AuthContentContainer
          title="Sign up"
          subheading={
            <Text>
              Already have an account?{" "}
              <TextLink as={Link} to={loginPath}>
                Sign in
              </TextLink>
            </Text>
          }
        >
          <form onSubmit={handleSubmit(handleValidSubmit)}>
            <VStack spacing="6" alignItems="start">
              {signupError && (
                <Alert
                  variant="error"
                  message={getFronteggErrorMessage(
                    signupError,
                    "Signup failed",
                  )}
                  width="100%"
                />
              )}
              <FormControl isInvalid={!!formState.errors.email}>
                <LabeledInput
                  label="Email"
                  error={formState.errors.email?.message}
                  variant="stretch"
                >
                  <Input
                    {...register("email", {
                      required: "Email is required.",
                    })}
                    autoCorrect="off"
                    placeholder="Enter your email"
                    size="lg"
                    variant={formState.errors.email ? "error" : "default"}
                  />
                </LabeledInput>
              </FormControl>

              <FormControl isInvalid={!!formState.errors.name}>
                <LabeledInput
                  label="Name"
                  error={formState.errors.name?.message}
                  variant="stretch"
                >
                  <Input
                    {...register("name", {
                      required: "Name is required.",
                    })}
                    autoCorrect="off"
                    placeholder="Enter your name"
                    size="lg"
                    variant={formState.errors.email ? "error" : "default"}
                  />
                </LabeledInput>
              </FormControl>

              <FormControl isInvalid={!!formState.errors.companyName}>
                <LabeledInput
                  label="Company name"
                  error={formState.errors.companyName?.message}
                  variant="stretch"
                >
                  <Input
                    {...register("companyName", {
                      required: "Company name is required.",
                    })}
                    autoCorrect="off"
                    placeholder="Enter your company name"
                    size="lg"
                    variant={formState.errors.companyName ? "error" : "default"}
                  />
                </LabeledInput>
              </FormControl>

              <Button
                variant="primary"
                size="lg"
                type="submit"
                isLoading={isSignupPending}
                spinner={<Spinner />}
                width="100%"
              >
                Sign up
              </Button>
              <OauthLoginButtons />
            </VStack>
          </form>
        </AuthContentContainer>
      )}
    </AuthLayout>
  );
};

export const SignupSuccess = (props: { email: string }) => {
  return (
    <AuthContentContainer title="Thanks for signing up!">
      <Text>
        We&apos;ve sent an email to {props.email} to verify your address.
      </Text>
    </AuthContentContainer>
  );
};
