import config from "~/config";

export const AUTH_ROUTES = {
  loginPath: `${config.basename}/account/login`,
  logoutPath: `${config.basename}/account/logout`,
  logoutUrl: `${config.basename}/account/logout`,
  activateUrl: `${config.basename}/account/activate`,
  impersonationUrl: `${config.basename}/account/impersonate`,
  acceptInvitationUrl: `${config.basename}/account/invitation/accept`,
  forgetPasswordUrl: `${config.basename}/account/forget-password`,
  resetPhoneNumberUrl: `${config.basename}/account/reset-phone-number`,
  resetPasswordUrl: `${config.basename}/account/reset-password`,
  socialLoginCallbackUrl: `${config.basename}/account/social/success`,
  signUpUrl: `${config.basename}/account/sign-up`,
  oidcRedirectUrl: `${config.basename}/account/oidc/callback`,
  samlCallbackUrl: `${config.basename}/account/saml/callback`,
  magicLinkCallbackUrl: `${config.basename}/account/login/magic-link`,
  hostedLoginRedirectUrl: `${config.basename}/oauth/callback`,
  openAppUrl: `${config.basename}/account/redirect`,
};

const AUTH_ROUTE_PATHS = new Set(Object.values(AUTH_ROUTES));

export function isAuthRoute() {
  const pathname = window.location.pathname;
  return AUTH_ROUTE_PATHS.has(pathname);
}
