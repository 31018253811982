import React from "react";
import { Navigate, Route } from "react-router-dom";

import { AUTH_ROUTES } from "~/fronteggRoutes";
import { AuthLayout } from "~/layouts/AuthLayout";
import { AuthRoutes } from "~/platform/auth/AuthRoutes";
import { AuthenticatedRoutes } from "~/platform/AuthenticatedRoutes";
import { useRefreshToken, useVerifyMfa } from "~/queries/frontegg";
import { SentryRoutes } from "~/sentry";

import { MfaForm } from "./auth/Login";

export const UnauthenticatedRoutes = () => {
  return (
    <SentryRoutes>
      <Route path="/account/*" element={<AuthRoutes />} />
      <Route path="*" element={<AuthenticatedRoutesWrapper />} />
    </SentryRoutes>
  );
};

export const AuthenticatedRoutesWrapper = () => {
  const {
    data: { isLoggedIn, response },
  } = useRefreshToken();
  const verifyMfaMutation = useVerifyMfa();

  if (!verifyMfaMutation.isSuccess && response?.mfaRequired) {
    return (
      <AuthLayout>
        <MfaForm
          verifyMfaMutation={verifyMfaMutation}
          mfaToken={response.mfaToken}
        />
      </AuthLayout>
    );
  }
  if (!isLoggedIn) {
    const fullPath = location.pathname + location.search + location.hash;
    const redirectUrl = encodeURIComponent(fullPath);
    return (
      <Navigate to={`${AUTH_ROUTES.loginPath}?redirectUrl=${redirectUrl}`} />
    );
  }

  return <AuthenticatedRoutes />;
};
