import { ContextHolder, useLoginActions } from "@frontegg/react";
import * as Sentry from "@sentry/react";

import config from "~/config";
import { AUTH_ROUTES } from "~/fronteggRoutes";
import { fronteggQueryKeys, UseIsLoggedInData } from "~/queries/frontegg";
import { queryClient } from "~/queryClient";
import { fronteggLoginDisabled } from "~/utils/frontegg";

import { fetchRefreshAccessToken } from "./frontegg";
import { TokenRefreshResponse } from "./frontegg/types";

let currentToken: string | null = null;

export type RequestAuthorizeAction = ReturnType<
  typeof useLoginActions
>["requestAuthorize"];

/**
 * Holds the current Frontegg accessToken value in module state, which allows us to
 * automatically refresh the token if we get a 401.
 */
export function getFronteggToken() {
  return currentToken;
}

/**
 * Clears the fronteggToken held in module state, used when logging a user out.
 */
export function clearFronteggToken() {
  queryClient.setQueryData<UseIsLoggedInData>(fronteggQueryKeys.isLoggedIn(), {
    isLoggedIn: false,
  });
  currentToken = null;
}

/**
 * Updates the fronteggToken held in module state.
 */
export function setFronteggToken(token: string | null) {
  if (!token) {
    Sentry.captureException(
      new Error("Frontegg token is empty, null or undefined"),
    );
  }
  queryClient.setQueryData<UseIsLoggedInData>(fronteggQueryKeys.isLoggedIn(), {
    isLoggedIn: Boolean(token),
  });
  currentToken = token;
}

export async function refreshToken() {
  // No frontegg login during impersonation
  if (config.environmentdOverride) return null;

  let response: TokenRefreshResponse;
  try {
    response = await fetchRefreshAccessToken();
  } catch (error) {
    const fullPath = location.pathname + location.search + location.hash;
    const redirectUrl = encodeURIComponent(fullPath);
    const loginPath = `${AUTH_ROUTES.loginPath}?redirectUrl=${redirectUrl}`;
    // If this call fails, we assume the user's refresh token is expired
    // and kick them out to the login page.
    if (fronteggLoginDisabled()) {
      // We can't easily trigger router navigation here, so reload the page.
      window.location.href = loginPath;
    } else {
      // Frontegg watches the url for changes, so we can avoid a full reload.
      history.pushState(undefined, "", loginPath);
    }
    Sentry.addBreadcrumb({
      level: "error",
      category: "auth",
      message: "Failed to refresh auth token",
      data: error as Error,
    });
    return null;
  }

  setFronteggToken(response.accessToken);
  // Update the token the Frontegg admin panel uses
  ContextHolder.for("default").setAccessToken(response.accessToken);

  return response;
}
