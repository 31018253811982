import {
  Avatar,
  ButtonProps,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Tag,
  Text,
  useBreakpointValue,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { AdminPortal } from "@frontegg/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { useSegment } from "~/analytics/segment";
import { getCurrentTenant, useCurrentOrganization } from "~/api/auth";
import { User } from "~/api/frontegg/types";
import ThemeSwitcher from "~/components/ThemeSwitcher";
import config from "~/config";
import { AUTH_ROUTES } from "~/fronteggRoutes";
import { useSwitchOrganization } from "~/hooks/useSwitchOrganization";
import { NAV_HORIZONTAL_SPACING, NAV_HOVER_STYLES } from "~/layouts/constants";
import docUrls from "~/mz-doc-urls.json";
import { useCurrentUser, useTenants } from "~/queries/frontegg";
import { MaterializeTheme } from "~/theme";

const ProfileDropdown = ({
  isCollapsed,
  ...props
}: ButtonProps & { isCollapsed: boolean }) => {
  const { colors } = useTheme<MaterializeTheme>();
  const { data: user } = useCurrentUser();
  // This comes from the global api, which is available during impersonation, which is
  // why we use this instead of tenantsState to show the org name.
  const { organization } = useCurrentOrganization();
  const menuPlacement = useBreakpointValue({
    base: "bottom" as const,
    lg: "top-end" as const,
  });

  const isImpersonating = !!config.impersonation;

  return (
    <Menu gutter={1} placement={menuPlacement}>
      <MenuButton
        aria-label="Profile"
        title="Profile"
        px={NAV_HORIZONTAL_SPACING}
        py={1}
        borderRadius={{ base: "lg", lg: "sm" }}
        _hover={{ lg: NAV_HOVER_STYLES }}
        _active={NAV_HOVER_STYLES}
        borderWidth={{ base: "1px", lg: "0" }}
        borderColor={colors.border.secondary}
        {...props}
      >
        <HStack spacing={2}>
          <Avatar size="xs" src={user?.profilePictureUrl} name={user?.name} />
          {!isCollapsed && (
            <VStack alignItems="flex-start" spacing={0} width="100%">
              <Text
                textStyle="text-ui-med"
                minW="100%"
                maxW="160px"
                noOfLines={1}
                textAlign="left"
                color={colors.foreground.primary}
              >
                {isImpersonating ? "Impersonation User" : user?.name}
              </Text>
              <Text
                textStyle="text-small"
                fontWeight="500"
                color={colors.foreground.secondary}
                minW="100%"
                maxW="160px"
                textAlign="left"
                noOfLines={1}
                // noOfLines sets display, so this has to come after it
                display={{ base: "none", lg: "-webkit-box" }}
              >
                {organization?.name ?? config.impersonation?.organizationId}
              </Text>
            </VStack>
          )}
        </HStack>
      </MenuButton>
      <MenuList pb={2}>
        {!isImpersonating && (
          <>
            <VStack
              px="3"
              pt="3"
              pb="2"
              align="left"
              lineHeight="1.3"
              spacing="0"
            >
              <Text fontWeight="semibold">{user?.name}</Text>
              <Text mt="1" fontSize="xs" color={colors.foreground.secondary}>
                {user?.email}
              </Text>
            </VStack>
            <MenuDivider />
          </>
        )}
        {!isImpersonating && (
          <>
            <OrganizationMenuGroup user={user} />
            <MenuDivider />
          </>
        )}
        <ThemeSwitcher />
        <MenuDivider />
        <ProfileMenuItems isImpersonating={isImpersonating} />
        <MenuDivider />
        <CommunityMenuItems />
        {!isImpersonating && (
          <>
            <MenuDivider />
            <MenuItem
              fontWeight="medium"
              as={RouterLink}
              to={AUTH_ROUTES.logoutPath}
              color={colors.accent.red}
            >
              Sign out
            </MenuItem>
          </>
        )}
      </MenuList>
    </Menu>
  );
};

export const OrganizationMenuGroup = ({ user }: { user: User }) => {
  const switchOrganization = useSwitchOrganization();
  const {
    data: { tenants },
  } = useTenants();
  const tenantSwitchingEnabled = tenants.length > 1;
  const currentTenant = getCurrentTenant(user, tenants);

  return (
    <MenuGroup title="Organization">
      {tenants
        .filter((tenant) => tenant && tenant.name)
        .sort((t1, t2) =>
          // always show orgs in the same order
          t1.name.toLowerCase() < t2.name.toLowerCase() ? -1 : 1,
        )
        .map((tenant) => (
          <MenuItem
            key={`org-${tenant.tenantId}`}
            isDisabled={
              !tenantSwitchingEnabled ||
              currentTenant?.tenantId === tenant.tenantId
            }
            title={
              tenantSwitchingEnabled &&
              currentTenant?.tenantId !== tenant.tenantId
                ? "Set as active organization"
                : "Current organization"
            }
            justifyContent="space-between"
            gap="var(--ck-space-2)"
            _disabled={{
              opacity: 1,
              cursor: "default",
              background: "none",
            }}
            _active={{
              // clicking flashes a background when disabled without this
              background: "none",
            }}
            _hover={{
              background:
                currentTenant?.tenantId === tenant.tenantId
                  ? "none"
                  : "default",
              cursor:
                currentTenant?.tenantId === tenant.tenantId
                  ? "default"
                  : "pointer",
            }}
            onClick={() => switchOrganization(tenant.tenantId)}
          >
            {tenant.name}{" "}
            {currentTenant?.tenantId === tenant.tenantId && (
              <Tag size="sm" colorScheme="lavender">
                active
              </Tag>
            )}
          </MenuItem>
        ))}
    </MenuGroup>
  );
};

export const ProfileMenuItems = ({
  isImpersonating,
}: {
  isImpersonating: boolean;
}) => {
  return (
    <VStack spacing={0} width="100%">
      <MenuItem
        fontWeight="medium"
        onClick={() => AdminPortal.show()}
        isDisabled={isImpersonating}
        title={
          isImpersonating
            ? "Unsupported under impersonation"
            : "Manage your account and organization"
        }
      >
        Account settings
      </MenuItem>
      <MenuItem
        as={RouterLink}
        to="https://materialize.com/s/pricing"
        fontWeight="medium"
        target="_blank"
        title="See Materialize pricing"
      >
        Pricing
      </MenuItem>
    </VStack>
  );
};

const CommunityMenuItems = () => {
  const { track } = useSegment();

  return (
    <VStack spacing={0} width="100%">
      <MenuItem
        as={RouterLink}
        to={docUrls["/docs/"]}
        onClick={() => {
          track("Link Click", {
            label: "Docs",
            href: docUrls["/docs/"],
          });
        }}
        fontWeight="medium"
        target="_blank"
        title="View Materialize documentation"
      >
        Documentation
      </MenuItem>
      <MenuItem
        as={RouterLink}
        to="https://materialize.com/s/chat"
        fontWeight="medium"
        target="_blank"
      >
        Join us on Slack
      </MenuItem>
      <MenuItem
        as={RouterLink}
        to={docUrls["/docs/support/"]}
        fontWeight="medium"
        target="_blank"
      >
        Help Center
      </MenuItem>
    </VStack>
  );
};

export default ProfileDropdown;
